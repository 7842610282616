<template>
  <div class="home">
      <Header></Header>
       <div class="skills-main-box">
           <div class="skills-banner-box">
               <div class="top-tit-a">
                   <p class="p1 cursor"  @click="goProv">ZUI使用技巧</p>
                   <span class="shu">|</span>
                   <p class="p1" v-if="dataMessage.positionList&&dataMessage.positionList[1].assetList">{{dataMessage.positionList[1].assetList[0].asset.baseText}}</p>
               </div>
           </div>
           <div class="skills-content">
            <div class="top-banner-img" @click="playVideo" v-if="dataMessage&&backgroundShow">
                    <div class="bg-img">
                        <div class="bg-img-rel">
                            <div class="video-logo">
                                <img src="../assets/img/skills-video-logo.png" alt="" />
                            </div>
                        </div>
                    </div>
                   <img v-if="dataMessage.positionList&&dataMessage.positionList[0].assetList" :src="dataMessage.positionList[0].assetList[0].asset.url" alt="">
               </div>
               <div class="top-banner-img" v-show="dataMessage&&!backgroundShow">
                   <!-- <img src="../assets/img/skills-content-img.jpg" alt=""> -->
                   <video ref="videoPlayer" controls v-if="dataMessage.positionList&&dataMessage.positionList[3].assetList" :src="dataMessage.positionList[3].assetList[0].asset.url"></video>
               </div>

               <div class="bottom-text-main" v-if="dataMessage">
                    <div class="top-logo-text">
                        <p class="name-logo" v-if='dataMessage.extContentInfo&&dataMessage.extContentInfo.a1b8c36854b145e8902683d8ddb12739'>{{dataMessage.extContentInfo.a1b8c36854b145e8902683d8ddb12739[0].title}}</p>
                        <p class="time">{{timestampToTime(dataMessage.publishTime)}}</p>
                    </div>
                    <p class="big-p" v-if="dataMessage.positionList&&dataMessage.positionList[1].assetList">{{dataMessage.positionList[1].assetList[0].asset.baseText}}</p>
                    <p class="two-p" v-if="dataMessage.positionList&&dataMessage.positionList[2].assetList">{{dataMessage.positionList[2].assetList[0].asset.baseText}}</p>
                    <p class="big-p2">支持机型</p>
                    <div class="about-slide" v-if="dataMessage.extContentInfo">
                        <div class="slide" @click="selectFn(item2.title)" v-for="(item2,index2) in dataMessage.extContentInfo.CUSTOMER_PORTAL_PRODUCT_NUMBER" :key="index2">{{item2.title}}</div>
                
                    </div>
                </div>
           </div>
           <div class="next-prov-box">
               <div class="prov-box">
                   <p class="p1">上一篇</p>
                   <p class="p2" v-if="thisNawIndex==0">暂无数据</p>
                   <p class="p2" @click="getNewMsgFn(allMsg[thisNawIndex-1].id)" v-if="thisNawIndex!=0">{{allMsg[thisNawIndex-1].positionList[1].assetList[0].asset.baseText}}</p>
               </div>
               <div class="next-box">
                   <p class="p1">下一篇</p>
                   <p class="p2" v-if="allMsg.length<=1||thisNawIndex==(allMsg.length-1)">暂无数据</p>
                   <p class="p2" @click="getNewMsgFn(allMsg[thisNawIndex+1].id)" v-if="allMsg.length>1&&thisNawIndex!=(allMsg.length-1)">{{allMsg[thisNawIndex+1].positionList[1].assetList[0].asset.baseText}}</p>
               </div>
           </div>
       </div>
    <Footer></Footer>
  </div>
</template>
<script>
//设置html font-size
document.documentElement.style.fontSize =
  document.documentElement.clientWidth / 38.4 + "px";
window.onresize = () => {
  document.documentElement.style.fontSize =
    document.documentElement.clientWidth / 38.4 + "px";
};
/* 
var blockIdArr={
    335 最新消息Banner
    327 最新消息>内容详情
    362 更新信息>机型图
    334 更新记录>内容详情
    333 内测机型Banner
    366 内侧机型>机型列表
    363 二维码-微信
    364 二维码-微博
    indexBanner:335,
    newMsg:327,
    newsjixing:362,
    update:334,
    neicebanner:333,
    neicejixing:366,
    vxcode:363,
    wbcode:364
}
*/
//接口地址引入
import interfaces from "../utils/baseUrl";
import axios from 'axios';
import Header from "../components/Header"
import Footer from "../components/Footer"
import instance from "../utils/http"
import tezignSdk from '@tezignlab/tezign-external-track';
import clickPlugin from '@tezignlab/tezign-external-track/dist/esm/plugins/click';
import pageViewPlugin from '@tezignlab/tezign-external-track/dist/esm/plugins/page-view';

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      //详情
      dataMessage:{},
      //所有信息
      //所有新闻
      allMsg:[],
      //首页banner
      bannerdata:"",
      //最新消息列表
      newMsg:[],
      //更新信息banner
      updatedata:[
        {coverPicture:''}
      ],
      rightMoreArr:[],
      bannerUrl:'',
      pageNum:1,
      pageSize:20,
      searchText:'',
      leftNavList:[],
      leftNavListStatus:false,
      showIndex:0,
      //左侧导航code
      leftCode:'',
      leftValue:"",
      navigators:[],
      allNavigators:[],
      thisNawIndex:0,
      backgroundShow:true,
    };
  },
  beforeMount: function () {
    //获取链接参数
    var query = this.$route.query;
    //设置整体展厅参数
    // this.cacheKey = query.cacheKey;
    this.getContent(
        {
            "token": "",
            "param": {
                "id": query.id
            }   
        }
    )
    this.getContent2()
  },
  //初始化执行
  created: function () {
    let $this = this;
    // $this.getBaseInfo(335)
  },
  //事件
  methods: {
    updateHtmlMsg(){
        const token = localStorage.getItem("access-token");
          if(token){
            // 手动埋点
            var httpMsg=tezignSdk.track({
              event_type_code: 'Portal_Content_View',
              event_properties: {
                data_source: 'external_sdk',
                portal_id:this.dataMessage.portalId?this.dataMessage.portalId:0,
                block_id:this.dataMessage.blockId?this.dataMessage.blockId:0,
                content_id:this.dataMessage.id?this.dataMessage.id:0,
                asset_id:this.dataMessage.coreId?this.dataMessage.coreId:0,
              }
            })
            console.log(httpMsg)
          }
       },
        playVideo(){
            this.backgroundShow=false;
            this.$refs.videoPlayer.play()
        },
        getNewMsgFn(id){
           this.getContent(
                {
                    "token": "",
                    "param": {
                        "id":id
                    }   
                }
            ) 
            this.getContent2()
        },
        //获取内容详情
        getContent(data){
          this.dataMessage={};
          var $this = this;
          //console.log(this)
           instance.post(`/open-api/standard/v1/getContentDetail`,data,{ headers: { 'Content-Type': "application/json","Token-type":'bearer' } }).then(res=>{
                console.log(res.data.result)
                if(res&&res.data){
                    $this.dataMessage=res.data.result
                    $this.updateHtmlMsg()
                }else{
                    instance.post(`/open-api/standard/v1/getContentDetail`,data,{ headers: { 'Content-Type': "application/json","Token-type":'bearer' } }).then(res=>{
                        if(res&&res.data){
                            $this.dataMessage=res.data.result
                             $this.updateHtmlMsg()
                        }else{
                            
                        }
                    })
                }
          })
        },
        getContent2(code,value){
            var data
            var $this = this;
            if(code&&value){
                data={
                    "token": "",
                    "param": {
                        "blockId": interfaces.blockIdArr.skillBlockID,
                        pageNum: this.pageNum,
                        pageSize: this.pageSize,
                        "filterConditionList": [],
                        "filterConditionList":[
                            {
                            "code":  code,
                            "value":[value],
                            "type":4
                            }
                        ]
                    }
                }
            }else{
                data={
                    "token": "",
                    "param": {
                        "blockId": interfaces.blockIdArr.skillBlockID,
                        pageNum: this.pageNum,
                        pageSize: this.pageSize,
                        "filterConditionList": [],
                    }
                }
            }
            
            instance.post(`/open-api/standard/v1/searchContent`,data,{ headers: { 'Content-Type': "application/json","Token-type":'bearer' } }).then(res=>{
                if(res&&res.data.result){
                    this.allMsg=res.data.result.records
                    this.allMsg.filter((item,index)=>{
                        if(item.id==this.dataMessage.id){
                            this.thisNawIndex=index
                        }
                    })
                }else{
                    instance.post(`/open-api/standard/v1/searchContent`,data,{ headers: { 'Content-Type': "application/json","Token-type":'bearer' } }).then(res=>{
                        if(res&&res.data.result){
                            this.allMsg=res.data.result.records
                            this.allMsg.filter((item,index)=>{
                                if(item.id==this.dataMessage.id){
                                    this.thisNawIndex=index
                                }
                            })
                        }else{
                            
                        }
                    })
                }
                
                console.log(this.allMsg)
            })
        },
        timestampToTime(timestamp) {
            var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-';
            var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
            var D = date.getDate() + ' ';
            return Y+M+D;
        },
        goProv(id){
          this.$router.push({
            name: 'skillsMain',
          })
        },
        selectFn(searchText) {
            this.$router.push({
                name: "skillsSelect",
                query: {
                searchText:searchText,
                },
            });
        },
  },
};
</script>
<style lang="less" scoped> 
.cursor{
    cursor: pointer;
}
.skills-main-box{
  width: 100%;
  min-height: calc(100vh - 3rem);
  overflow: hidden;
  background: linear-gradient(180deg,#e6eef4 0,#fff 90.81%);
  position: relative;
  padding-top: .95rem;
  box-sizing: border-box;
  
}
.skills-main-box img{
	display: block;
	width: 100%;
}
.skills-main-box .skills-banner-box{
    position: relative;
    width: 1200px;
    margin: 0 auto;
    height:1.4rem;
    overflow: hidden;
}
.skills-main-box .skills-banner-box .top-tit-a{
    font-size:.24rem;
    color:#000000;
    overflow: hidden;
    display: flex;
    position: absolute;
    top:.9rem;
    left:0rem;
}
.skills-main-box .skills-banner-box .top-tit-a .p1{
    font-size:.24rem;
    color:#000000;
}
.skills-main-box .skills-banner-box .top-tit-a .shu{
    display: inline-block;
    padding: 0 .2rem;
    font-size:.24rem;
    color:#000000;
}
.skills-main-box .skills-banner-box .big-tit{
    position: absolute;
    top:2.15rem;
    left: 0;
    width: 100%;
    text-align: center;
    font-size:1rem;
    color: #000000;
    font-weight: 600;
}
.skills-main-box .skills-banner-box .slide-ul{
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 1.5rem;
    display: flex;
    justify-content: center;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box{
   width: auto;
   margin: 0 auto;
   overflow: hidden;
   height: 100%;
   position: relative;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li{
    float: left;
    width: 1.9rem;
    height: 100%;
    margin-left: 1rem;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li:nth-of-type(1){
    margin-left: 0;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .top-img{
    width: 100%;
    height:.8rem;
    overflow: hidden;
    position: relative;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .top-img img{
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .top-img .img1{
    display: block;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .top-img .img2{
    display: none;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .slide-li .bottom-p{
    width: 100%;
    text-align: center;
    font-size:.3rem;
    color: #999999;
}
.skills-main-box .skills-banner-box .slide-ul .slide-box .move-line{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1.9rem;
    height:.1rem;
    background: #5f7bfd;
}
.no-msg{
  width: 180px !important;
  margin: 0 auto;
  margin-top: 75px;
  padding-bottom: 75px;
}
.no-msg img{
  width: 100%;
}

.skills-content{
    padding:.3rem 0 .4rem 0;
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    border-bottom: 1px solid #cccccc;
}
.skills-content .top-banner-img{
    width: 100%;
    position: relative;
}
.skills-content .top-banner-img .bg-img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}
.skills-content .top-banner-img .bg-img .bg-img-rel{
    position: relative;
    width: 100%;
    height: 100%;
}
.skills-content .top-banner-img .bg-img .bg-img-rel .video-logo{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 2rem;
    height: 2rem;
}
.skills-content .top-banner-img video{
    width: 100%;
}
.skills-content .content-select-input select{
    border: none;
    font-size: 15px;
}
.skills-content .content-box{
    width: 100%;
    overflow: hidden;
}
.skills-content .content-box .content-slide{
    float: left;
    width: 280px;
    margin-left:25px;
    margin-top: 26px;
    border-radius: 10px;
    background: #f5f5f6;
    cursor: pointer;
    overflow: hidden;
    padding-bottom:10px;
}
.skills-content .content-box .content-slide:nth-of-type(4n+1){
    margin-left: 0;
}
.skills-content .content-box .content-slide .top-box-video{
    position: relative;
    width: 100%;
    height:200px;
    background:#ebebed;
}
.skills-content .content-box .content-slide .top-box-video .box-text{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    transform: translate(0,-50%);
    overflow: hidden;
}
.skills-content .content-box .content-slide .top-box-video .box-text .p1{
    font-size:24px;
    color:#7a6afb;
}
.skills-content .content-box .content-slide .top-box-video .box-text .p2{
    font-size:24px;
    color:#000000;
}
.skills-content .content-box .content-slide .top-box-video .block-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    display: none;
}
.skills-content .content-box .content-slide:hover .top-box-video .block-bg{
    display: block;
}
.skills-content .content-box .content-slide .top-box-video .block-bg .block-bg-rel{
    position: relative;
    width: 100%;
    height: 100%;
}
.skills-content .content-box .content-slide .top-box-video .block-bg .block-bg-rel .video-logo{
    position: absolute;
    top:146px;
    left:15px;
    width: 40px;
    height: 40px;
}
.skills-content .content-box .content-slide .top-box-video .block-bg .block-bg-rel .time-p{
    position: absolute;
    top:157px;
    left:63px;
    color: #ffffff;
    font-size:15px;
}
.skills-content .bottom-text-main{
    width: 100%;
    padding:20px 0;
    overflow: hidden;
}
.skills-content .bottom-text-main .top-logo-text{
    width: 100%;
    overflow: hidden;
}
.skills-content .bottom-text-main .top-logo-text .name-logo{
    float: left;
    padding:4px 8px;
    border-radius: 4px;
    background:#5f7bfd;
    font-size:12px;
    color: #ffffff;
}
.skills-content .bottom-text-main .top-logo-text .time{
    float: left;
    margin-left:6px;
    line-height:24px;
    font-size:12px;
    color:#999999;
}
.skills-content .bottom-text-main .big-p{
    margin-top:13px;
    font-size:18px;
    font-weight: 600;
    color: #000000;
}
.skills-content .bottom-text-main .big-p2{
    margin-top:13px;
    font-size:18px;
    font-weight: 600;
    color: #000000;
}
.skills-content .bottom-text-main .two-p{
    margin-top:9px;
    color:#666666;
    font-size: 14px;
}
.skills-content .bottom-text-main .about-slide{
    overflow: hidden;
    margin-top:10px;
    display: flex;
    flex-flow:row wrap;
}
.skills-content .bottom-text-main .about-slide .slide{
    float: left;
    padding:5px 10px;
    background: #ffffff;
    font-size:10px;
    color:#666666;
    border-radius: 5px;
    border: 1px solid #4f4f4f;
    cursor: pointer;
    margin-top: 7px;
   margin-right: 7px;
}
.skills-content .bottom-text-main .about-slide .slide:nth-of-type(1){
    margin-left: 0;
}
.skills-content .see-more{
    margin: 0 auto;
    margin-top:50px;
    width:78px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size:12px;
    color:#000000;
    border: 1px solid #000000;
    border-radius: 4px;
    cursor: pointer;
}
.next-prov-box{
    width: 1200px;
    margin: 0 auto;
    margin-top:61px;
    overflow: hidden;
}
.next-prov-box .prov-box{
    float: left;
}
.next-prov-box .prov-box .p1{
    color: #cccccc;
    font-size: 12px;
}
.next-prov-box .prov-box .p2{
    color: #000000;
    margin-top: 4px;
    font-size: 12px;
    font-weight: 600;
    text-decoration:underline;
    cursor: pointer;
}

.next-prov-box .next-box{
    float: right;
}
.next-prov-box .next-box .p1{
    color: #cccccc;
    font-size: 12px;
}
.next-prov-box .next-box .p2{
    color: #000000;
    margin-top: 4px;
    font-size: 12px;
    font-weight: 600;
    text-decoration:underline;
    cursor: pointer;
}
</style>